#Merch {
  width: 100%;
  overflow: hidden;
}
.Merch-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Merch-image {
  width: 65vw;
  object-fit: cover;
}
