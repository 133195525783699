* {
  box-sizing: border-box;
}
.delete_me_filler {
  box-sizing: border-box;
  height: 40vh;
  width: 100%;
  color: red;
  border: 10px solid blue;
}

@import url("https://fonts.googleapis.com/css?family=IM+Fell+DW+Pica");

body {
  margin: 0;
  padding: 0;
  font-family: "IM Fell DW Pica", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background: black;
}
.row {
  display: flex;
  width: 100%;
}
.col-1 {
  flex: 1;
}
.col-2 {
  flex: 2;
}
.col-3 {
  flex: 3;
}
.col-4 {
  flex: 4;
}
.col-5 {
  flex: 5;
}
.col-6 {
  flex: 6;
}
.col-7 {
  flex: 7;
}
.col-8 {
  flex: 8;
}
.col-9 {
  flex: 9;
}
.col-10 {
  flex: 10;
}
.col-11 {
  flex: 11;
}
.col-12 {
  flex: 12;
}
.center {
  display: flex;
  justify-content: center;
}
.pointer {
  cursor: pointer;
}
