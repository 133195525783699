.App {
  height: 100vh;
  background-image: url(https://s3.amazonaws.com/sniper66/background-texture-blood.jpg);
  min-height: 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.offset {
  width: 100%;
  overflow-y: auto;
  position: fixed;
  height: calc(100% - 117px);
  top: 115px;
}
.slim {
  width: 90%;
  margin: auto;
}
.slim.about_text {
  margin: 30px;
}
.background_texture {
  object-fit: cover;
  position: fixed;
  z-index: -1000;
}

@media screen and (orientation: landscape) {
  .background_texture {
    width: 100vw;
  }
}

@media screen and (orientation: portrait) {
  .background_texture {
    height: 100vh;
  }
}
.splash_img {
  width: 120%;
  transform: translate(-10%);
}

@media screen and (max-width: 400px) {
  .offset {
    top: 102px;
    height: calc(100% - 102px);
  }
}

@media screen and (max-width: 600px) {
  .App {
    background-image: url(https://s3.amazonaws.com/sniper66/mobile-background-texture-blood.jpg);
  }
}
