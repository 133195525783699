#Shows {
  /* margin: 0; */
  padding: 0;
  /* background: rgba(0, 0, 0, 0.5); */
}
.bit-widget-initializer {
  height: 100px;
  width: 100px;
  background: red;
  text-align: center;
}
