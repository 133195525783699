#Contact {
  margin: 30px;
}

form {
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin: auto;
}

label {
  font-family: IM Fell DW Pica, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 5px;
}

input,
textarea {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
}

#contact-button {
  width: 50%;
  background: rgba(0, 0, 0, 0.66);
  margin: 20px auto;
  color: white;
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  min-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-radius: 4px;
  text-transform: uppercase;
  border-style: none;
}
.error {
  width: 80vw;
  margin: auto;
  font-size: 1.25em;
  color: red;
}
