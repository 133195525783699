@import url(https://fonts.googleapis.com/css?family=IM+Fell+DW+Pica);
* {
  box-sizing: border-box;
}
.delete_me_filler {
  box-sizing: border-box;
  height: 40vh;
  width: 100%;
  color: red;
  border: 10px solid blue;
}

body {
  margin: 0;
  padding: 0;
  font-family: "IM Fell DW Pica", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background: black;
}
.row {
  display: flex;
  width: 100%;
}
.col-1 {
  flex: 1 1;
}
.col-2 {
  flex: 2 1;
}
.col-3 {
  flex: 3 1;
}
.col-4 {
  flex: 4 1;
}
.col-5 {
  flex: 5 1;
}
.col-6 {
  flex: 6 1;
}
.col-7 {
  flex: 7 1;
}
.col-8 {
  flex: 8 1;
}
.col-9 {
  flex: 9 1;
}
.col-10 {
  flex: 10 1;
}
.col-11 {
  flex: 11 1;
}
.col-12 {
  flex: 12 1;
}
.center {
  display: flex;
  justify-content: center;
}
.pointer {
  cursor: pointer;
}

.nav_container {
  z-index: 1;
  position: fixed;
  width: 100%;
}
.nav {
  background-image: url(https://s3.amazonaws.com/sniper66/background-texture-blood.jpg);
  /* min-height: 100vh; */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.nav_darken {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 10px 10px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.266);
}
.hr {
  margin: 0;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
#hamburger {
  font-size: 2.5em;
}
.sniper-logo-container {
  height: 65px;
}
.sniper-logo {
  object-fit: contain;
  height: 100%;
}
.top {
  width: 100%;
}
.top .icon-container {
  display: flex;
  justify-content: space-evenly;
}
.bottom .icon-container {
  display: none;
  justify-content: space-evenly;
}
.icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.link_bar {
  font-size: 2em;
  /* padding: 20px; */
  position: absolute;
  /* height: 200px; */
  /* width: 100px; */
  background-color: rgba(0, 0, 0, 0.266);
  transition: 0.3s;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transform: perspective(1000px) rotateX(-90deg);
          transform: perspective(1000px) rotateX(-90deg);
}
.link_bar.active {
  -webkit-transform: perspective(1000px) rotateX(-0);
          transform: perspective(1000px) rotateX(-0);
}
.link_bar ul {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
}
.link_bar ul li {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  color: rgba(255, 255, 255, 0.95);
}
.link_bar ul li:hover {
  background-color: rgba(0, 0, 0, 0.266);
  transition: 0.2s;
}
li a {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .nav {
    background-image: url(https://s3.amazonaws.com/sniper66/mobile-background-texture-blood.jpg);
  }
  .sm-col-12 {
    flex: 12 1;
  }
  .sm-hide {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .sniper-logo-container {
    height: 50px;
  }
}

#Home {
  overflow: hidden;
}

#Shows {
  /* margin: 0; */
  padding: 0;
  /* background: rgba(0, 0, 0, 0.5); */
}
.bit-widget-initializer {
  height: 100px;
  width: 100px;
  background: red;
  text-align: center;
}

#Contact {
  margin: 30px;
}

form {
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin: auto;
}

label {
  font-family: IM Fell DW Pica, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 5px;
}

input,
textarea {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
}

#contact-button {
  width: 50%;
  background: rgba(0, 0, 0, 0.66);
  margin: 20px auto;
  color: white;
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  min-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-radius: 4px;
  text-transform: uppercase;
  border-style: none;
}
.error {
  width: 80vw;
  margin: auto;
  font-size: 1.25em;
  color: red;
}

#Merch {
  width: 100%;
  overflow: hidden;
}
.Merch-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Merch-image {
  width: 65vw;
  object-fit: cover;
}

iframe {
  -webkit-overflow-scrolling: touch;
}
h1 center {
  font-family: "cassius", "IM Fell DW Pica", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 2em;
}
#Media {
  overflow: hidden;
}
.media {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.youtube-player {
  margin-bottom: 20px;
  width: 80%;
  display: flex;
  justify-content: center;
  height: 300px;
}
.youtube-player img {
  width: 100%;
  /* max-width: 480px; */
  height: 100%;
}
.media_iframe {
  border: 0;
  width: 100%;
  max-width: 480px;
}
.music_iframe {
  border: 0;
  width: 700px;
  margin: 10px 0;
}
@media screen and (min-width: 800px) {
  .media {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .youtube-player {
    width: 45%;
  }
}

.App {
  height: 100vh;
  background-image: url(https://s3.amazonaws.com/sniper66/background-texture-blood.jpg);
  min-height: 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.offset {
  width: 100%;
  overflow-y: auto;
  position: fixed;
  height: calc(100% - 117px);
  top: 115px;
}
.slim {
  width: 90%;
  margin: auto;
}
.slim.about_text {
  margin: 30px;
}
.background_texture {
  object-fit: cover;
  position: fixed;
  z-index: -1000;
}

@media screen and (orientation: landscape) {
  .background_texture {
    width: 100vw;
  }
}

@media screen and (orientation: portrait) {
  .background_texture {
    height: 100vh;
  }
}
.splash_img {
  width: 120%;
  -webkit-transform: translate(-10%);
          transform: translate(-10%);
}

@media screen and (max-width: 400px) {
  .offset {
    top: 102px;
    height: calc(100% - 102px);
  }
}

@media screen and (max-width: 600px) {
  .App {
    background-image: url(https://s3.amazonaws.com/sniper66/mobile-background-texture-blood.jpg);
  }
}

