iframe {
  -webkit-overflow-scrolling: touch;
}
h1 center {
  font-family: "cassius", "IM Fell DW Pica", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 2em;
}
#Media {
  overflow: hidden;
}
.media {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.youtube-player {
  margin-bottom: 20px;
  width: 80%;
  display: flex;
  justify-content: center;
  height: 300px;
}
.youtube-player img {
  width: 100%;
  /* max-width: 480px; */
  height: 100%;
}
.media_iframe {
  border: 0;
  width: 100%;
  max-width: 480px;
}
.music_iframe {
  border: 0;
  width: 700px;
  margin: 10px 0;
}
@media screen and (min-width: 800px) {
  .media {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .youtube-player {
    width: 45%;
  }
}
