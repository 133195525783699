.nav_container {
  z-index: 1;
  position: fixed;
  width: 100%;
}
.nav {
  background-image: url(https://s3.amazonaws.com/sniper66/background-texture-blood.jpg);
  /* min-height: 100vh; */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.nav_darken {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 10px 10px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.266);
}
.hr {
  margin: 0;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
#hamburger {
  font-size: 2.5em;
}
.sniper-logo-container {
  height: 65px;
}
.sniper-logo {
  object-fit: contain;
  height: 100%;
}
.top {
  width: 100%;
}
.top .icon-container {
  display: flex;
  justify-content: space-evenly;
}
.bottom .icon-container {
  display: none;
  justify-content: space-evenly;
}
.icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.link_bar {
  font-size: 2em;
  /* padding: 20px; */
  position: absolute;
  /* height: 200px; */
  /* width: 100px; */
  background-color: rgba(0, 0, 0, 0.266);
  transition: 0.3s;
  transform-origin: top;
  transform: perspective(1000px) rotateX(-90deg);
}
.link_bar.active {
  transform: perspective(1000px) rotateX(-0);
}
.link_bar ul {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
}
.link_bar ul li {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  color: rgba(255, 255, 255, 0.95);
}
.link_bar ul li:hover {
  background-color: rgba(0, 0, 0, 0.266);
  transition: 0.2s;
}
li a {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .nav {
    background-image: url(https://s3.amazonaws.com/sniper66/mobile-background-texture-blood.jpg);
  }
  .sm-col-12 {
    flex: 12;
  }
  .sm-hide {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .sniper-logo-container {
    height: 50px;
  }
}
